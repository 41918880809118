import React, { useState } from "react"
import styles from "./style.module.scss"
import cx from "classnames"
import Image from "gatsby-image"
import { useStaticQuery } from "gatsby"
import { Modal } from "react-bootstrap"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const hero = require("../../images/hero.png")

const Hero = props => {
  const cardImages = useStaticQuery(graphql`
    query cardImages {
      HERO: file(relativePath: { eq: "images/hero2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  const [show, setShow] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)

  const handleClose = () => setShow(false)
  const handleShow = title => {
    setShow(true)
    setModalTitle(title)
  }

  {
    /* <Image
      className="rounded w-100 h-100"
      style={{top: '-250px'}}
      fluid={cardImages.HERO?.childImageSharp?.fluid}
      alt="dolce flower wall with neon text"
    /> */
  }

  return (
    <div className={cx(styles.hero, "row flex-column flex-lg-row")}>
      <Modal centered show={show} onHide={handleClose} size="lg">
        <Modal.Header
          className={cx("text-light", {
            "bg-info": modalTitle === "Maxxras",
            "bg-danger": modalTitle === "Pairs",
            "bg-success": modalTitle === "Snap",
          })}
          closeButton
        >
          <Modal.Title>
            <h2>{modalTitle}</h2>
            <div>
              {modalTitle === "Maxxras" && (
                <small>Word game which contains 10 levels (Age 11-99)</small>
              )}
              {modalTitle === "Pairs" && (
                <small>A memory recall game (Age 10+)</small>
              )}
              {modalTitle === "Snap" && (
                <small>Popular, fast-paced card game (Age 9+)</small>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto" style={{ maxHeight: "420px" }}>
          {modalTitle === "Maxxras" && (
            <>
              <h4>Maxxras Description</h4>
              <p>
                Level 1 of the Maxxras word game is to build/create/form 3 or 4
                letter words and progress to the level 10 to build/create/form
                15 or more letter words. The first player to get rid of their
                cards outright wins that level and to get rid of the card the
                players have to make a word by using an alphabet letter from
                their card in-conjunction with other letters from the cards in
                the centre play area.
              </p>
              <p>
                Each letter can be used twice from a single card and in any
                sequence within the play area cards and the card nominated by
                the player to dispose. Maxxras is probably the only word game in
                the world that allows the players the opportunity of
                building/creating/forming a 45 letter word
                pneumonoultramicroscopicsilicovolcanokoniosis whilst playing
                level 10. “Watch out for the wild card”
              </p>
              <h4>Maxxras Playing Instructions</h4>
              <p>
                Maxxras is a word game of 10 levels by using the 56 alphabet
                cards, for example card 1 = A and card 14 = Z and all the other
                letters are paired and mirrored and therefore each letter can be
                used twice from a single card in a word and the letters can be
                used in any sequence. Objective: Take turns to build a word by
                using at least 1 alphabet letter from 1 of your own cards and
                using letters from the other cards in the centre play area. Then
                reveal/confirm the word to the other players to avoid picking up
                an additional card from the deck. The first player to get rid of
                their cards outright wins that level.
              </p>
              <h4>Start the game</h4>
              <p>
                Deal 7 random cards to each player. Place 3 or 4 cards face-up
                randomly and then place level 1 card next to the cards in the
                centre play area. Level 1 is creating 3 or 4 letter words. Take
                turns to build a word by using at least 1 alphabet letter from 1
                of the players own cards and using letters from the other cards
                in the centre play area. Once a player has created a word, place
                that card separately from the other cards in the centre play
                area and then reveal/confirm the word to the other players to
                avoid picking up an additional card from the deck and then place
                that card on top of any card in the centre play area. The first
                player to get rid of their cards outright wins that level. The
                player that wins the most number of levels is the Maxxras
                winner.
              </p>
              <p>
                How many cards to place in the centre play area? For example
                level 9 is to create a 14 to 22 letter word therefore players
                can decide to place 14 or 22 cards in the central area and then
                place level 9 card next to the cards and then deal 7 random
                cards to each player.
              </p>
              <p>
                Optional: After completing level 9 players can place 14 or 22
                cards in the central play area to play level 10 and then deal 7
                random cards to each player. Optional: From levels 3 to 10
                players can write down the word created to show the opponents.
                Optional: The card 14 “Z” can be utilised as the wild card from
                levels 3 to 10. Repeated words are allowed, If players decide
                not to allow repeated words than every word created must be
                written down to avoid confusion of repeated words during game
                play.
              </p>
            </>
          )}
          {modalTitle === "Pairs" && (
            <>
              <p>
                The objective of the game is to collect as many pairs as
                possible. Taking turns clockwise. First place all of the cards
                face-down separately.
              </p>
              <p>
                The first player turns up any two cards and if these cards are
                the same, the player collects these and continues to play.
              </p>
              <p>
                If the cards are not the same then they are turned face-down in
                the same position and then play moves clockwise to the next
                player in turn.
              </p>
              <p>
                Each player should watch carefully as the cards are turned over
                so they can remember the position of the cards for when it’s
                their turn.
              </p>
              <p>
                Play continues until all of the cards have been collected in
                pairs.
              </p>
              <p>The player with the most pairs is the winner.</p>
              <p>
                There are twenty eight possible pairs and the game is best
                played in a group.
              </p>
            </>
          )}
          {modalTitle === "Snap" && (
            <>
              <p>
                The dealer shuffles the cards and then deals all of the cards in
                the pack face-down to each player in turn.
              </p>
              <p>
                Players then take turns placing the top card from their pile in
                front of them face-up creating a playing pile of cards facing
                up.
              </p>
              <p>
                When two cards of the same letters are turned up in the centre
                playing pile and the first player to shout “snap” wins the round
                and picks up all the cards from the centre playing pile and
                placing them face-up next to the cards they are holding
                face-down.
              </p>
              <p>
                If two players shout “snap” together or by mistake, the cards
                they have turned up and won are taken from them and placed
                face-up in the centre to form a pool (a second pile) of cards
                next to the (first pile) playing pile.
              </p>
              <p>
                Play continues and if a card is turned over with the same
                letters as the card on the pool (second pile) the first player
                to shout “snap” takes all the cards from the pool (second pile)
                and the (first pile) playing pile and adds them face-down to the
                bottom of the cards they are holding. The player to win all the
                cards is the game winner.
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
      <div
        id="hero"
        className="col bg-light-muted"
        style={{ borderRadius: "30px" }}
      >
        <div className={cx("row", styles.heroContainer)}>
          <div className="col position-relative">
            {/* <img
              src={hero}
              className={cx(
                styles.heroImage,
                "rounded w-100 position-absolute"
              )}
            /> */}

            <Image
              // className="rounded w-100 h-100"
              className={cx(styles.heroImage, "rounded w-100 h-100")}
              fluid={cardImages.HERO?.childImageSharp?.fluid}
              alt="dolce flower wall with neon text"
            />
          </div>
        </div>

        <div className={cx("row", styles.gameCards)}>
          <div
            onClick={() => handleShow("Maxxras")}
            className="col-4 col-md-4 bg-info d-flex flex-column align-items-center justify-content-center text-light p-3 p-md-4 text-center"
          >
            <h3>Maxxras</h3>
            <div className="d-none d-md-block">
              <small>Word game which contains 10 levels</small>
            </div>
            <div
              className="position-absolute d-none d-md-block"
              style={{ right: 10, top: 10 }}
            >
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 11 - 99
            </div>
            <p className="d-block d-md-none mt-3 mb-0">
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 11 - 99
            </p>
          </div>
          <div
            onClick={() => handleShow("Pairs")}
            className="col-4 col-md-4 bg-danger d-flex flex-column align-items-center justify-content-center text-light p-3 p-md-4 text-center"
          >
            <h3>Pairs</h3>
            <div className="d-none d-md-block">
              <small>A memory recall game</small>
            </div>
            <div
              className="position-absolute d-none d-md-block"
              style={{ right: 10, top: 10 }}
            >
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 10+
            </div>
            <p className="d-block d-md-none mt-3 mb-0">
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 10+
            </p>
          </div>
          <div
            onClick={() => handleShow("Snap")}
            className="col-4 col-md-4 bg-success d-flex flex-column align-items-center justify-content-center text-light p-3 p-md-4 text-center"
          >
            <h3>Snap</h3>
            <div className="d-none d-md-block">
              <small>Popular, fast-paced card game</small>
            </div>
            <div
              className="position-absolute d-none d-md-block"
              style={{ right: 10, top: 10 }}
            >
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 9+
            </div>
            <p className="d-block d-md-none mt-3 mb-0">
              <FontAwesomeIcon  icon={faInfoCircle} /> Age 9+
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
