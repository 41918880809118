import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import Categories from "../components/Categories"
import cx from "classnames"
import { navigate, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import ImageGallery from "react-image-gallery"
import LiteYouTubeEmbed from "react-lite-youtube-embed"

import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import styles from "./index.module.scss"

import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

const HomePage = ({ data, location }) => {
  const [isHovering, setIsHovering] = useState(false)

  const images = [
    {
      original: "/1. Alphabet Runner Games box front.jpeg",
      thumbnail: "/1. Alphabet Runner Games box front.jpeg",
      originalClass: styles.originalClass,
    },
    {
      original: "/2. Alphabet Runner Games box back.jpg",
      thumbnail: "/2. Alphabet Runner Games box back.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "/6. Back of the cards.jpg",
      thumbnail: "/6. Back of the cards.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "/Seal of approval 2021.JPG",
      thumbnail: "/Seal of approval 2021.JPG",
      originalClass: styles.originalClass,
    },
    {
      original: "/3. Alphabet Runner Games 14 cards & GTG logo.jpg",
      thumbnail: "/3. Alphabet Runner Games 14 cards & GTG logo.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "/4. Place 3 cards next to the Level 1 card.jpg",
      thumbnail: "/4. Place 3 cards next to the Level 1 card.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "/5. Player 1 has used the Z card to create the word ZONE.jpg",
      thumbnail: "/5. Player 1 has used the Z card to create the word ZONE.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "Maxxras Rules website Page 1.jpg",
      thumbnail: "Maxxras Rules website Page 1.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "Maxxras Rules website Page 2.jpg",
      thumbnail: "Maxxras Rules website Page 2.jpg",
      originalClass: styles.originalClass,
    },
    {
      original: "/7. Alphabet Runner Games 8 pack CDU.JPG",
      thumbnail: "/7. Alphabet Runner Games 8 pack CDU.JPG",
      originalClass: styles.originalClass,
    },
  ]

  const cardImages = useStaticQuery(graphql`
    query images {
      amazon: file(relativePath: { eq: "images/amazon-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      tgtg: file(relativePath: { eq: "images/tgtg.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      game2005: file(relativePath: { eq: "images/2005.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
      ig: file(relativePath: { eq: "images/ig.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  function openInNewTab(url) {
    window.open(url, "_blank").focus()
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    console.log("here!!!!")
    document.body.classList.remove("bg-dark-purple")
    document.body.classList.add("bg-light")
  }, [location])

  return (
    <Layout location={location}>
      <SEO />
      <div id="home-container" className={cx(styles.homeContainer, "row")}>
        <div className="col">
          <div className="row">
            <div className="col">
              <Hero />
            </div>
          </div>
          <div className="row">
            <div
              className="col text-center mx-auto"
              style={{ maxWidth: "650px" }}
            >
              <h3 className="mt-5 mb-3 text-success font-weight-bold">
                PRACTICE THE GAME
              </h3>
              <div className="mt-3">
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => navigate("/play")}
                >
                  Play Now <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
              <h2 className="font-weight-normal mt-5">
                MaxxRas WORD GAME is one of the most challenging word games in
                the world
              </h2>
              <h4 className="mt-5 mb-4">
                <strong>
                  Alphabet Runner Games is 3 games in 1 set of cards
                </strong>
              </h4>
              <h5 className="mt-3 mb-4">
                <strong>
                  <span className="text-info">Maxxras</span> is for the word
                  game fans in the family and for the players that like being
                  challenged. It's an ideal travel game.
                </strong>
              </h5>
              <h5 className="mt-0 mb-0">
                <strong>
                  The famous card games{" "}
                  <span className="text-danger">Pairs</span> and{" "}
                  <span className="text-success">Snap</span> are for the young
                  players to enjoy and have fun with the alphabet cards
                </strong>
              </h5>
            </div>
          </div>
          <div id="how-to-play" className="row my-4 my-md-5">
            <div className="col text-center">
              <h1 className="display-5 mt-5 mb-4">How to play</h1>
              <div className="mx-auto bg-light-muted">
                <LiteYouTubeEmbed
                  poster="maxresdefault"
                  id="TU-a0naeEno"
                  title="MaxxRas WORD GAME in 2 minutes!"
                />
              </div>
            </div>
          </div>
          <div id="buy" className="row my-4 my-md-5">
            <div className="col text-center rounded">
              <h1 className="display-5 mt-5 mb-2">Where to buy</h1>
              <h5 className="mb-3 text-muted">30-day refund guarantee</h5>
              <p>
                You can purchase the Alphabet Runner card games from anyone of
                these online suppliers:
              </p>
              <div
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                className={cx("mx-auto p-4 row", styles.whereToBuy, {
                  [`${styles.grayScale}`]: isHovering,
                })}
                style={{ backgroundColor: "white" }}
              >
                <div
                  className="col-12 col-md-4 position-relative"
                  onClick={() =>
                    openInNewTab(
                      "https://www.amazon.co.uk/Alphabet-Runner-Games-Educational-Traditional/dp/B09XBQXTK8/ref=sr_1_54?crid=1RPN4SBC114RZ&keywords=alphabet+runner+games&qid=1650109977&sprefix=%2Caps%2C136&sr=8-54"
                    )
                  }
                >
                  <Image
                    className="rounded w-100 h-100"
                    fluid={cardImages.amazon?.childImageSharp?.fluid}
                  />
                </div>
                <div
                  className="col-12 col-md-4"
                  onClick={() =>
                    openInNewTab(
                      "https://imaginationgaming.co.uk/product/alphabet-runner-games"
                    )
                  }
                >
                  <Image
                    className="rounded w-100 h-100"
                    fluid={cardImages.ig?.childImageSharp?.fluid}
                  />
                </div>
                <div
                  className="col-12 col-md-4"
                  onClick={() =>
                    openInNewTab(
                      "https://www.goodplayguide.com/reviews/alphabet-runner-games/"
                    )
                  }
                >
                  <Image
                    className="rounded w-100 h-100"
                    fluid={cardImages.tgtg?.childImageSharp?.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col text-center"
              // style={{ maxWidth: "650px", height: '' }}
            >
              <div className={cx(styles.imageGallery, "m-auto")}>
                <ImageGallery
                  items={images}
                  showFullscreenButton={false}
                  showPlayButton={false}
                />
              </div>
            </div>
          </div>

          <div className="row m-auto" style={{ maxWidth: "700px" }}>
            <div className="col text-center rounded">
              <h1 className="display-5 mt-5 mb-4">
                Alphabet Runner Board Game{" "}
                <span className="text-muted">(Release 2005)</span>
              </h1>
              <Image
                className="rounded"
                fluid={cardImages.game2005?.childImageSharp?.fluid}
              />
            </div>
          </div>

          <div
            id="contact"
            className="row m-auto"
            style={{ maxWidth: "700px" }}
          >
            <div className="col">
              <h1 className="display-5 mt-5 mb-4 text-center">Contact</h1>
              {/* <p>
                You can send us an email on <a href="mailto:webmaster@example.com">Jon Doe</a>
              </p> */}
              <Form name="contact" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <Form.Group controlId="name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="Full Name"
                    placeholder="Full Name"
                    size="lg"
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    name="Email"
                    type="email"
                    required
                    placeholder="Enter email"
                    size="lg"
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="message">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                    name="Message"
                    as="textarea"
                    rows={3}
                    size="lg"
                    required
                    placeholder="Write a message..."
                  />
                </Form.Group>

                <Button variant="danger" size="lg" block type="submit">
                  Send Message
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default HomePage
